#root {
  display: grid;
  grid-template-columns: 1fr minmax(0px, 40%);
  height: 100vh;
  font-size: 1.125rem;
  font-weight: normal;
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

#root .Button,
#root button {
  font-family: 'Helvetica Neue', Helvetica, sans-serif;
}

#root .Input-input {
  color: #232129;
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-column: 1/2;
  height: 100vh;
  overflow-y: scroll;
  padding: 20px;
}

.image-panel {
  background-size: cover;
  background-position: center;
  height: 100%;
}

.formik-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
}

.wrapper {
  max-width: 600px;
  width: 100%;
}

.wrap-logo {
  max-width: 600px;
  width: 100%;
}

.route-container {
  position: relative;
}

.wrap-content {
  width: 470px;
  padding-bottom: 40px;
  /* width: 100%; */
}

.hidden {
  display: none;
}

.flex-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.Icons-tick--white {
  color: #fff;
  font-size: 20px;
  left: 6px;
  position: absolute;
  top: -5px;
  z-index: 1;
}

.Icons-prev-step {
  font-size: 30px;
  line-height: 30px;
  width: 25px;
}

@media (max-width: 470px) {
  .wrap-content {
    width: 100%;
  }
}

@media (max-width: 850px) {
  .content {
    grid-column: span 2;
  }

  .image-panel {
    display: none;
  }
}

/* CSS Transitions for router */
.formik-form > div {
  position: relative;
}

.fade-enter {
  opacity: 0.001;
  transform: translateZ(0);
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms cubic-bezier(1, 0, 1, 1);
}

.fade-exit {
  opacity: 1;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.fade-exit-active {
  opacity: 0.001;
  transition: opacity 150ms cubic-bezier(0, 0, 0, 1);
}

.image-fade-enter {
  opacity: 0.001;
  transform: translateZ(0);
  z-index: 2;
}

.image-fade-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-out;
}

.image-fade-exit {
  opacity: 1;
  transform: translateZ(0);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}

.image-fade-exit-active {
  opacity: 1;
}

/* to later be incorporated in the lld theme */

.Button:disabled {
  background-color: #d9d9d9 !important;
  border: none;
}

a {
  text-decoration: none;
  font-weight: bold;
}
